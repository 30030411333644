.wrap {
    text-align: center;
    background-color: #282c34;
    min-height: 100dvh;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    margin-top: 10px;
    opacity: 0.8;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.errorMessage {
    width: 80%;
    font-size: 19px;
}

.recommendationMessage {
    display: inline-block;
    width: 80%;
    font-size: 14px;
    margin-top: 20px;
}