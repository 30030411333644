.container {
  text-align: center;
  background-color: #282c34;
  min-height: 100dvh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
}

.links {
  color: #61dafb;
  cursor: pointer;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.close {
  background-color: #61dafb;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  color: #282c34;
  border-radius: 50px;
  width: 20px;
  position: absolute;
  right: 20px;
  top: 10px;
  opacity: .8;
}

.logo {
  width: 100px;
}

.block {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.label {
  margin: 15px 5px 5px;
  font-size: 18px;
  font-weight: 600;
}

.label_desc {
  font-size: 12px;
  width: 250px;
  margin: 0;
  margin-top: 5px;
}

.label_accept {
  font-size: 14px;
  margin: 0;
}

.label_inside {
  position: absolute;
  color: #696b6e;
  left: 20px;
  margin-top: 5px;
  font-size: 18px;
}

.labels_box {
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  width: 250px;
  height: 40px;
  border-radius: 20px;
  border: 0;
  text-align: center;
  margin-top: 5px;
  font-size: 16px;
  padding: 0 10px;
}

.input_file {
  margin-top: 5px;
  font-size: 16px;
  width: 250px;
}

.input_checkbox {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.buttonSmall {
  margin-top: 10px;
  width: 220px;
  height: 33px;
  border-radius: 50px;
  border: 0;
  font-size: 14px;
  color: white;
  background-color: red;
  cursor: pointer;
}

.block_group {
  display: flex;
  justify-content: center;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  font-size: 14px;
}

.button {
  margin-top: 30px;
  width: 220px;
  height: 50px;
  border-radius: 50px;
  border: 0;
  font-size: 16px;
  color: white;
  background-color: red;
  cursor: pointer;
}

.buttonAccept {
  margin-top: 10px;
  width: 220px;
  height: 50px;
  border-radius: 50px;
  border: 0;
  font-size: 14px;
  color: white;
  background-color: #1373e8;
  cursor: pointer;
}