.wrap {
    text-align: center;
    background-color: #282c34;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.errorMessage {
    width: 80%;
    font-size: 19px;
    color: red;
}

.recommendationMessage {
    display: inline-block;
    width: 80%;
    font-size: 14px;
    margin-top: 20px;
    color: white;
}