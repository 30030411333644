.cropButtons {
    position: fixed;
    z-index: 200;
    bottom: 15%;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 80%;
    font-size: 4vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.submitCrop {
    width: 40%;
    text-align: center;
    background-color: white;
    color: #282C34;
    border: none;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 8%);
    border-radius: 5px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cancelCrop {
    width: 40%;
    text-align: center;
    background-color: white;
    color: #282C34;
    border: none;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 8%);
    border-radius: 5px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cropArea {
    width: 20%;
    position: fixed;
    top: 20vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 200;
    color: white;
    display: flex;
    gap: 20px;
}