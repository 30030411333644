@keyframes showIn {
    from {
        top: -50px;
    }

    to {
        top: 0;
    }
}

.wrap {
    width: 100%;
    height: 40px;
    position: fixed;
    top: 0;
    left: 0;
    color: white;
    background-color: red;
    z-index: 9999;
    font-size: 16px;
    text-align: center;
    padding-top: 30px;
    animation: showIn .5s ease-in-out;
    border-radius: 0 0 20px 20px;
}