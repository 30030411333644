.container {
    text-align: center;
    background-color: #282c34;
    min-height: 100dvh;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 80px;
}

.insta {
    width: 80px;
    margin-top: 20px;
    opacity: 0.8;
}